import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Marker from "../../../assets/images/locations/marker.svg";
import MarkerHighlighted from "../../../assets/images/locations/marker-highlighted.svg";
import MapImg from "../../../assets/images/locations/map.svg";
import classNames from "classnames";
import LocationDetails from "../LocationDetails/LocationDetails";

import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  mapWrapper: {
    display: "inline-block",
    position: "relative",
    alignSelf: "center",
    marginTop: "160px",
    marginLeft: "91px",
    marginRight: "91px",
    zIndex: "22",
  },
  map: {
    height: "auto",
    maxWidth: "100%",
    width: "auto",
  },
  markers: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  locationDetails: {
    position: "absolute",
    left: "70%",
    top: "35%",
  },
  marker: {
    position: "absolute",

    transform: "translateX(0%) translateY(0%)",
    cursor: "pointer",

    "&:hover": {
      transform: "translateX(-8px) translateY(-17px)",
    },
  },
  markerHighlighted: {
    transform: "translateX(-8px) translateY(-17px)",
    cursor: "pointer",

    "&:hover": {
      transform: "translateX(-8px) translateY(-17px)",
    },
  },
  markerDubai: {
    left: "62%",
    top: "43.5%",
  },
  markerAbuDhabi: {
    left: "61.5%",
    top: "44%",
  },
  markerSaudiArabia: {
    left: "59.5%",
    top: "44%",
  },
  markerUSA: {
    left: "26.5%",
    top: "31%",
  },
  markerSouthAfrica: {
    left: "54.5%",
    top: "77%",
  },
  markerIndia: {
    left: "68%",
    top: "45%",
  },
}));

const defaultLocation = {
  city: "UAE",
  address: (
    <>
      <b>Dubai</b>: 806 Tameem House, Tecom, Dubai
      <br />
      <br />
      <b>Abu Dhabi</b>: Office 17, Al Mariah Mall, Abu Dhabi
    </>
  ),
  country: "United Arab Emirates",
  phone: "+971 (0)4 874 6677",
  css: "markerDubai",
  countryImage: (
    <StaticImage
      src="../../../assets/images/locations/locations_dubai.jpg"
      draggable="false"
    />
  ),
};

const Map = ({ locations, setLocationImg, setIsImageChange }) => {
  const classes = useStyles();
  const [locationDetails, setLocationDetails] = useState(defaultLocation);

  const handleMarkerMouseOver = (event, location) => {
    if (!locationDetails || location.css !== locationDetails.css)
      event.target.src = MarkerHighlighted;
  };

  const handleMarkerMouseOut = (event, location) => {
    if (!locationDetails || location.css !== locationDetails.css)
      event.target.src = Marker;
  };

  const handleMarkerClick = (event, location) => {
    setLocationDetails(location);
    setLocationImg(location.countryImage);
    setIsImageChange(false);
  };

  return (
    <div className={classes.mapWrapper}>
      <img src={MapImg} className={classes.map} />
      <div className={classes.markers}>
        {locationDetails && (
          <div className={classes.locationDetails}>
            <LocationDetails locationDetails={locationDetails} />
          </div>
        )}
        {locations.map((location) => {
          return (
            <div
              className={classNames([classes.marker], [classes[location.css]], {
                [classes.markerHighlighted]:
                  locationDetails && locationDetails.css === location.css,
              })}
              onMouseOver={(event) => {
                handleMarkerMouseOver(event, location);
              }}
              onMouseOut={(event) => {
                handleMarkerMouseOut(event, location);
              }}
              onClick={(event) => {
                handleMarkerClick(event, location);
              }}
            >
              {/* {location.css === 'markerDubai' ? <img src={MarkerHighlighted} /> : locationDetails && locationDetails.css === location.css && <img src={MarkerHighlighted} /> || <img src={Marker} /> } */}
              {(locationDetails && locationDetails.css === location.css && (
                <img src={MarkerHighlighted} />
              )) || <img src={Marker} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Map;
