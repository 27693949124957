import Dubai from "../../assets/images/locations/zq-lee-VbDjv8-8ibc-unsplash.jpg";
import SouthAfrica from "../../assets/images/locations/thomas-bennie-B21WLsX6a5c-unsplash.jpg";
import Delaware from "../../assets/images/locations/josefina-lacroze-PsxFKMiEOM8-unsplash.jpg";
import SaudiArabia from "../../assets/images/locations/SaudiArabia.jpg";
import AbuDhabi from "../../assets/images/locations/AbuDhabi.jpg";
import India from "../../assets/images/locations/AbuDhabi.jpg";
export const locations = [
  {
    city: "Dubai",
    address: "806 Tameem House, Tecom, Dubai, UAE",
    phone: "+971 (0)4 874 6677",
    css: "markerDubai",
    country: "United Arab Emirates",
    countryImage: Dubai,
  },
  {
    city: "Abu Dhabi",
    address: "Office 17, Al Mariah Mall, Abu Dhabi, UAE",
    phone: "+971 (0)4 874 6677",
    css: "markerAbuDhabi",
    country: "United Arab Emirates",
    countryImage: AbuDhabi,
  },
  {
    city: "Saudi Arabia",
    address: "Sari Street, Jeddah 23423",
    phone: "+966 12 630 0001",
    css: "markerSaudiArabia",
    country: "Saudi Arabia",
    countryImage: SaudiArabia,
  },
  {
    city: "USA",
    address: `1209 Orange Street,
    Wilmington, DE 19801`,
    phone: "+1 302 440 1477",
    css: "markerUSA",
    country: "United States of America",
    countryImage: Delaware,
  },
  {
    city: "South Africa",
    address: `51 Gumtree Crescent 
    1 Palm Villas 
    Mount Edgecombe 
    Durban 
    Kwazulu Natal 
    South Africa 4302`,
    phone: "+27 (60) 789 5911",
    css: "markerSouthAfrica",
    country: "South Africa",
    countryImage: SouthAfrica,
  },
  {
    city: "India",
    address: "TBD",
    phone: "+971 (0)4 874 6677",
    css: "markerIndia",
    country: "India",
    countryImage: India,
  },
];
