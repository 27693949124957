import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { locations } from "../Locations.data";
import LocationDetails from "../LocationDetails/LocationDetails";
import { groupByCountry } from "../Locations.helper";
import Typography from "../../Typography/Typography";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: "100%",

      "& .MuiAccordion-root": {
        boxShadow: "none",
        margin: 0,

        "&::before": {
          background: "none"
        }
      },

      "& .MuiAccordionSummary-root": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        height: "56px"
      },

      "& .Mui-expanded svg": {
        fill: "#4001C5"
      },

      "& .MuiAccordionDetails-root": {
        padding: 0,
        paddingBottom: "24px",
        display: "block",
        background:
          "linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)"
      }
    },
    heading: {
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "134.3%",
      color: "#000000"
    }
  })
);

const LocationsAccordion = () => {
  const classes = useStyles();

  const groupedByCountry = groupByCountry(locations);

  return (
    <div className={classes.root}>
      {Object.keys(groupedByCountry).map((key, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography customVariant={"bodyLargeRegular"} component="span">
                {key}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {groupedByCountry[key].map(locationDetails => {
                return <LocationDetails locationDetails={locationDetails} />;
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default LocationsAccordion;
