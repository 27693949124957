var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const groupByCountry = locations => {
  const groupedByCountry = groupBy(locations, "country");
  return groupedByCountry;
};
